<template>
  <vca-card class="shadowed">
    <vca-field :label="$t('taking.info')">
      <vca-row>
        <vca-column>
          <vca-row>
            <h3>Name:</h3>
            <h3 class="test-center">{{ value.name }}</h3>
          </vca-row>
          <vca-row>
            <h3>Crew:</h3>
            <h3 class="test-center">{{ value.crew.name }}</h3>
          </vca-row>
          <vca-row>
            <h3>Event:</h3>
            <h3 class="test-center">
              <a :href="eventLink(value.event.id)"> {{ value.event.name }}</a>
            </h3>
          </vca-row>
        </vca-column>
        <vca-column>
          <br />
          <div :key="index" v-for="(res, index) in value.sources">
            <vca-row class="row-padding" v-if="!res.edit">
              <div class="margin-auto">
                {{ $t("taking.sources." + res.value + ".title") }}
              </div>
              <div class="margin-auto">
                {{ res.description }}
              </div>
              <div class="bold margin-auto text-center">
                {{ formatMoney(res.money) }}
              </div>
            </vca-row>
          </div>
          <h3>--------------------------------------------</h3>
          <vca-row class="row-padding">
            <div>{{ $t("taking.list.open") }}</div>
            <div></div>
            <label class="bold margin-auto text-center">
              {{ formatMoney(value.state.open) }}
            </label>
          </vca-row>
          <vca-row class="row-padding">
            <div>{{ $t("taking.list.wait") }}</div>
            <div></div>
            <label class="bold margin-auto text-center">
              {{ formatMoney(value.state.wait) }}
            </label>
          </vca-row>
          <vca-row class="row-padding">
            <div>{{ $t("taking.list.confirmed") }}</div>
            <div></div>
            <label class="bold margin-auto text-center">
              {{ formatMoney(value.state.confirmed) }}
            </label>
          </vca-row>

          <br />
          <h3>--------------------------------------------</h3>
          <h3 v-if="!value.state.no_income">
            {{ $t("taking.form.source.amount.sum_total") }}
            {{ sum_total }} €
          </h3>
          <h3 v-else>{{ $t("taking.form.no_income.title") }}</h3>
        </vca-column>
      </vca-row>
    </vca-field>
  </vca-card>
</template>
<script>
export default {
  name: "TakingEditInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    sum_total() {
      return (
        this.value
          ? this.value.sources.reduce(
              (sum, a) => sum + (a.money ? parseInt(a.money.amount) : 0),
              0
            ) / 100
          : 0
      ).toLocaleString();
    },
    sum_cash() {
      return (
        this.value
          ? this.value.sources.reduce(
              (sum, a) =>
                sum +
                (!a.has_external && a.money ? parseInt(a.money.amount) : 0),
              0
            ) / 100
          : 0
      ).toLocaleString();
    },
    sum_external() {
      return (
        this.value
          ? this.value.sources.reduce(
              (sum, a) =>
                sum +
                (a.has_external && a.money ? parseInt(a.money.amount) : 0),
              0
            ) / 100
          : 0
      ).toLocaleString();
    },
  },
  methods: {
    eventLink(value) {
      return "/events/" + value;
    },
  },
};
</script>
<style lang="scss">
.activity_status {
  text-align: right;
}
</style>
