<template>
  <div>
    <div v-if="edit_view">
      <TakingEditForm v-model="edit" ref="form" />
      <div>
        <button class="vca-button" @click="update()">
          {{ $t("button.save") }}
        </button>
        <div class="vca-center">
          <vca-cancel-button
            @click="close_edit()"
            :placeholder="$t('button.clear')"
          />
        </div>
      </div>
    </div>
    <div v-if="!edit_view">
      <TakingEditInfo v-model="edit" />
      <button class="vca-button" @click="start_edit()">
        {{ $t("button.edit") }}
      </button>
      <div v-if="showRemove" class="vca-center">
        <vca-cancel-button
          @click="remove()"
          :placeholder="$t('button.delete')"
        />
      </div>
    </div>
    <TakingEditActivity v-model="edit"></TakingEditActivity>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TakingEditForm from "@/components/finance/takings/edit/TakingEditForm";
import TakingEditActivity from "./edit/TakingEditActivity.vue";
import TakingEditInfo from "./edit/TakingEditInfo.vue";
export default {
  name: "TakingEdit",
  components: { TakingEditForm, TakingEditActivity, TakingEditInfo },
  data() {
    return {
      edit_view: false,
    };
  },
  methods: {
    start_edit() {
      this.edit_view = true;
      this.$store.commit("takings/start_edit");
    },
    close_edit() {
      this.edit_view = false;
      this.$store.commit("takings/close_edit");
    },
    update() {
      if (this.isInvalid) {
        this.$refs.form.validate();
      } else {
        this.$store.dispatch({ type: "takings/update" }).then(() => {
          this.$store.dispatch({
            type: "takings/getByID",
            data: this.$route.params.id,
          });
          this.edit_view = false;
        });
      }
    },
    remove() {
      if (!confirm(this.$t("taking.delete.confirm"))) {
        return false;
      }
      this.$store
        .dispatch({ type: "takings/deleteByID", data: this.$route.params.id })
        .then(() => {
          this.$router.push({ path: "/finances/takings" });
        });
    },
  },
  validations() {
    return this.validation;
  },
  computed: {
    isInvalid() {
      return this.$v.edit.$invalid || this.invalidSources != null;
    },
    invalidSources() {
      return this.edit.sources.find((el) => el.money.amount == 0);
    },
    edit: {
      set(value) {
        this.$store.commit("takings/edit", value);
      },
      get() {
        return this.$store.state.takings.edit;
      },
    },
    ...mapGetters({
      validation: "takings/validations",
    }),
    showRemove() {
      if (
        this.$store.state.takings.edit.event.id === "" &&
        this.$store.state.takings.edit.deposit_units.length === 0
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
