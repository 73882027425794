<template>
  <vca-card>
    <h2>{{ $t("takings.edit.header") }}</h2>
    <TakingEdit v-if="loaded" />
  </vca-card>
</template>
<script>
import TakingEdit from "@/components/finance/takings/TakingEdit.vue";
export default {
  name: "TakingsEdit",
  components: {
    TakingEdit,
  },
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.$store.dispatch({ type: "crews/public" });
    this.$store
      .dispatch({
        type: "takings/getByID",
        data: this.$route.params.id,
      })
      .then(() => {
        this.loaded = true;
      });
  },
};
</script>
